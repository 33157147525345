import React from 'react';

export default function FeatureCheat() {
    return (
        <div >
            <h3 className = "langCng" lang="en">Cheating</h3>
            <p className = "langCng" lang="en">You can display a specific tag by tapping a specific place.</p>

            <h3 className="langCng" lang="ja">いかさま</h3>
            <p className="langCng" lang="ja">特定の場所をタップすると、特定のタグを表示することができます.</p>

            <h3 className = "langCng" lang="ru">Мошенничество</h3>
            <p className = "langCng" lang="ru">Вы можете отобразить определенный тег, коснувшись определенного места.</p>

            <h3 className = "langCng" lang="fr">Tricherie</h3>
            <p className = "langCng" lang="fr">Vous pouvez afficher une étiquette spécifique en appuyant sur un endroit spécifique.</p>

            <h3 className = "langCng" lang="de">Schummeln</h3>
            <p className = "langCng" lang="de">Sie können ein bestimmtes Tag anzeigen, indem Sie auf einen bestimmten Ort tippen.</p>

            <h3 className = "langCng" lang="it">Imbrogliare</h3>
            <p className = "langCng" lang="it">Puoi visualizzare un tag specifico toccando un punto specifico.</p>

            <h3 className = "langCng" lang="zh">作弊</h3>
            <p className = "langCng" lang="zh">您可以通过点击特定位置来显示特定标签。</p>

            <h3 className = "langCng" lang="ko">사기</h3>
            <p className = "langCng" lang="ko">특정 장소를 탭하여 특정 태그를 표시할 수 있습니다.</p>

            <h3 className = "langCng" lang="fi">Pettäminen</h3>
            <p className = "langCng" lang="fi">Voit näyttää tietyn tunnisteen napauttamalla tiettyä paikkaa.</p>

            <h3 className = "langCng" lang="es">Infiel</h3>
            <p className = "langCng" lang="es">Puede mostrar una etiqueta específica tocando un lugar específico.</p>

            <h3 className = "langCng" lang="tr">Hile</h3>
            <p className = "langCng" lang="tr">Belirli bir yere dokunarak belirli bir etiketi görüntüleyebilirsiniz.</p>

            <h3 className = "langCng" lang="ms">Menipu</h3>
            <p className = "langCng" lang="ms">Anda boleh memaparkan teg tertentu dengan mengetik tempat tertentu.</p>

            <h3 className = "langCng" lang="ta">ஏமாற்றுதல்</h3>
            <p className = "langCng" lang="ta">ஒரு குறிப்பிட்ட இடத்தைத் தட்டுவதன் மூலம் குறிப்பிட்ட குறிச்சொல்லைக் காட்டலாம்.</p>

            <h3 className = "langCng" lang="id">selingkuh</h3>
            <p className = "langCng" lang="id">Anda dapat menampilkan tag tertentu dengan mengetuk tempat tertentu.</p>

            <h3 className = "langCng" lang="pt">Trapaça</h3>
            <p className = "langCng" lang="pt">Você pode exibir uma tag específica tocando em um local específico.</p>

            <h3 className = "langCng" lang="hi">धोखा</h3>
            <p className = "langCng" lang="hi">आप किसी विशिष्ट स्थान को टैप करके एक विशिष्ट टैग प्रदर्शित कर सकते हैं।</p>

            <h3 className = "langCng" lang="ar">الغش</h3>
            <p className = "langCng" lang="ar">يمكنك عرض علامة محددة من خلال النقر على مكان معين.</p>

        </div>
    );
  }